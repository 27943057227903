import React, { FC } from 'react';
import styled from 'styled-components';

import { device } from 'src/theme/breakpoints';

const Container = styled.div`
  max-width: 936px;

  & ul:not(+ h2) {
    margin-bottom: 36px;
    max-width: 840px;
  }

  & a {
    margin-top: 36px;
    max-width: 226px;

    @media ${device.mobileDOWN} {
      margin: 24px auto 0;
    }
  }
`;

interface FixedWidthColProps {
  children: React.ReactNode;
}

const FixedWidthCol: FC<FixedWidthColProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default FixedWidthCol;
