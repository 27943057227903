import React, { FC, useId } from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'src/theme/colors';
import { PlusIcon, MinusIcon } from 'src/assets/icons';
import { device } from 'src/theme/breakpoints';
import { AccordionVariant } from './constants';

const Item = styled.div`
  box-sizing: border-box;
`;

const Title = styled.button<{ $variant: AccordionVariant; $active: boolean }>`
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  font-size: 16px;
  text-align: left;
  width: 100%;

  ${({ $variant }) =>
    $variant === AccordionVariant.ALFA &&
    css`
      align-items: center;
      background-color: transparent;
      border: 1px solid ${colors.grey600};
      box-sizing: border-box;
      column-gap: 8px;
      display: flex;
      font-weight: 500;
      justify-content: space-between;
      line-height: 1.6;
      padding: 16px;
      position: relative;

      @media ${device.laptopSUP} {
        font-size: 18px;
        padding: 24px;
      }

      & > svg {
        min-width: 20px;
      }
    `}

  ${({ $variant, $active }) =>
    $variant === AccordionVariant.BRAVO &&
    css`
      background-color: transparent;
      border: none;
      border-left: 2px solid
        ${$active ? `${colors.green400}` : `${colors.grey200}`};
      font-weight: 700;
      margin: 0;
      padding: 4px 32px 16px;

      @media ${device.mobileDOWN} {
        padding: 4px 16px 8px;
      }
    `}
`;

const Content = styled.div<{ $active: boolean; $variant: AccordionVariant }>`
  height: ${({ $active }) => ($active ? 'auto' : '0')};
  max-height: ${({ $active }) => ($active ? '1000px' : '0')};
  overflow: hidden;
  visibility: ${({ $active }) => ($active ? 'visible' : 'hidden')};

  ${({ $variant, $active }) =>
    $variant === AccordionVariant.ALFA &&
    css`
      border: 1px solid ${colors.grey600};
      border-top: 0;

      ${$active &&
      css`
        padding: 24px 24px 16px;
      `}
    `}

  ${({ $variant, $active }) =>
    $variant === AccordionVariant.BRAVO &&
    css`
      border-left: 2px solid ${colors.green400};

      & > p:not(:last-child),
      & > ul:not(:last-child) {
        margin-bottom: 16px;
      }

      & > p:not(:first-child) {
        padding-top: 16px;
      }

      ${$active &&
      css`
        padding: 16px 32px;

        @media ${device.mobileDOWN} {
          padding: 16px 8px 16px 16px;
        }
      `}
    `}
`;

export interface IAccordionItem {
  content: React.ReactNode;
  title: string;
}

interface AccordionItemProps {
  active?: boolean;
  item: IAccordionItem;
  onToggle: () => void;
  variant: AccordionVariant;
}

const AccordionItem: FC<AccordionItemProps> = ({
  active = false,
  item,
  onToggle,
  variant,
}) => {
  const { content, title } = item;
  const id = useId();

  return (
    <Item>
      <Title
        onClick={onToggle}
        id={`${id}-header`}
        aria-controls={`${id}-panel`}
        aria-expanded={active}
        $variant={variant}
        $active={active}
      >
        {title}
        {variant === AccordionVariant.ALFA ? (
          <>{active ? <MinusIcon /> : <PlusIcon />}</>
        ) : (
          <></>
        )}
      </Title>
      <Content
        $active={active}
        id={`${id}-panel`}
        aria-labelledby={`${id}-header`}
        aria-hidden={!active}
        $variant={variant}
      >
        {content}
      </Content>
    </Item>
  );
};

export default AccordionItem;
