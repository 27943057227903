import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { device } from 'src/theme/breakpoints';
import Button, { ButtonVariant } from 'components/Button';
import useMediaQuery from 'src/utils/hooks/use-media-query';
import AccordionItem, { IAccordionItem } from './AccordionItem';
import { AccordionVariant } from './constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @media ${device.mobileDOWN} {
    row-gap: 16px;
  }

  & > button {
    align-self: center;
  }
`;

interface AccordionProps {
  items: IAccordionItem[];
  firstActive?: boolean;
  variant: AccordionVariant;
}

const Accordion: FC<AccordionProps> = ({ items, firstActive, variant }) => {
  const [clicked, setClicked] = useState<number>(firstActive ? 0 : -1);
  const [showMore, setShowMore] = useState<boolean>(false);
  const isMobile = useMediaQuery(`${device.mobileDOWN}`);

  const handleToggle = (index: number) => {
    if (clicked === index) {
      return setClicked(-1);
    }
    setClicked(index);
  };

  return (
    <Wrapper>
      {isMobile && variant !== AccordionVariant.BRAVO ? (
        <>
          {items.slice(0, 3).map((item, i) => (
            <AccordionItem
              key={i}
              item={item}
              onToggle={() => handleToggle(i)}
              active={clicked == i}
              variant={variant}
            />
          ))}
          {showMore &&
            items.slice(3).map((item, i) => (
              <AccordionItem
                key={i + 3} // Use `i + 3` to ensure unique keys
                item={item}
                onToggle={() => handleToggle(i + 3)}
                active={clicked == i + 3}
                variant={variant}
              />
            ))}
          {!showMore && items.length > 3 && (
            <Button
              variant={ButtonVariant.BRAVO}
              onClick={() => setShowMore(true)}
            >
              <Trans i18nKey="common_read_more_faq" />
            </Button>
          )}
        </>
      ) : (
        items.map((item, i) => (
          <AccordionItem
            key={i}
            item={item}
            onToggle={() => handleToggle(i)}
            active={clicked == i}
            variant={variant}
          />
        ))
      )}
    </Wrapper>
  );
};

export default Accordion;
