import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import SEO from 'components/SEO';
import Banner from 'components/Banner';
import Layout from 'components/Layout';
import Content from 'components/Content';
import Link, { LinkVariant } from 'components/Link';
import SectionTitle from 'components/SectionTitle';
import List from 'components/List';
import Note, { NoteVariant } from 'components/Note';
import { LINKS } from 'src/utils/constants';
import Accordion, { AccordionVariant } from 'components/Accordion';
import { device } from 'src/theme/breakpoints';
import Table from 'components/Table';
import { getChannelsTableHeaders } from 'src/data/tables/channels-table-headers';
import { getImportChannelsTableData } from 'src/data/tables/import-channels-table-data';
import Button, { ButtonVariant } from 'components/Button';
import { useModal } from 'components/Modal/ModalContext';
import ContactForm from 'components/ContactForm';
import FixedWidthCol from 'components/FixedWidthCol';
import { HeadingScope } from 'components/Heading';

export const Hero = styled(Banner)`
  @media ${device.mobileDOWN} {
    padding-bottom: 24px;
  }
`;

export const InfoSection = styled.div`
  align-items: flex-start;
  display: flex;
  column-gap: 48px;

  & > :last-child {
    max-width: 640px;
  }

  @media ${device.tabletDOWN} {
    flex-direction: column;
    row-gap: 24px;
  }

  @media ${device.desktop} {
    column-gap: 80px;
  }
`;

export const ChannelSection = styled.div`
  align-items: center;
  column-gap: 80px;
  display: flex;
  justify-content: space-between;
  scroll-margin-top: 80px;

  & > :last-child {
    flex: 1 0 376px;

    @media ${device.laptopDOWN} {
      display: none;
    }
  }
`;

export const CTA = styled(Link)`
  margin-top: 40px;
  max-width: 226px;

  @media ${device.mobileDOWN} {
    margin: 40px auto;
  }
`;

const PIIPage: FC = () => {
  const { t } = useTranslation();
  const channelsTableHeaders = getChannelsTableHeaders();
  const importChannelsTabledata = getImportChannelsTableData();
  const { openModal } = useModal();

  return (
    <Layout footerHasMargin>
      <Hero
        image={
          <StaticImage
            src="../assets/images/features/product-information-import/pii-main.png"
            alt={t('pii_heading')}
            layout="fullWidth"
          />
        }
        title={t('pii_heading')}
        text={t('pii_text')}
      >
        <Link variant={LinkVariant.CHARLIE} url="#channels">
          <Trans i18nKey="pii_cta" />
        </Link>
      </Hero>
      <HeadingScope>
        <Content>
          <FixedWidthCol>
            <SectionTitle subtitle={t('pii_benefits_subtitle')}>
              <Trans i18nKey="pii_benefits_title" />
            </SectionTitle>
            <List items={[t('pii_benefits_1'), t('pii_benefits_2')]} />
            <Note variant={NoteVariant.ALFA}>
              <p>
                <Trans i18nKey="pii_benefits_note" />
              </p>
            </Note>
            <Link url={LINKS.REGISTER} variant={LinkVariant.BRAVO}>
              <Trans i18nKey="common_try_free_of_charge" />
            </Link>
          </FixedWidthCol>
          <SectionTitle>
            <Trans i18nKey="pii_import_title" />
          </SectionTitle>
          <InfoSection>
            <StaticImage
              src="../assets/images/features/product-information-import/pii-import-en.jpg"
              alt={t('pii_import_title')}
              width={620}
              height={620}
              quality={100}
            />
            <Accordion
              firstActive
              variant={AccordionVariant.BRAVO}
              items={[
                {
                  title: t('pii_import_1_title'),
                  content: (
                    <>
                      <p>
                        <Trans i18nKey="pii_import_1_text_0" />{' '}
                      </p>
                      <List
                        items={[
                          t('pii_import_1_list_1'),
                          t('pii_import_1_list_2'),
                          t('pii_import_1_list_3'),
                          t('pii_import_1_list_4'),
                          t('pii_import_1_list_5'),
                        ]}
                        columns={2}
                      />
                      <p>
                        <Trans i18nKey="pii_import_1_text_1" />
                        <Button
                          variant={ButtonVariant.CHARLIE}
                          onClick={openModal}
                        >
                          <Trans i18nKey="pii_import_1_text_2" />
                        </Button>
                        <Trans i18nKey="pii_import_1_text_3" />
                      </p>
                    </>
                  ),
                },
                {
                  title: t('pii_import_2_title'),
                  content: (
                    <>
                      <p>
                        <Trans i18nKey="pii_import_2_text_0" />{' '}
                      </p>
                      <List
                        items={[
                          t('pii_import_2_list_1'),
                          t('pii_import_2_list_2'),
                          t('pii_import_2_list_3'),
                        ]}
                      />
                      <Note variant={NoteVariant.ALFA}>
                        <p>
                          <Trans i18nKey="pii_import_2_note" />
                        </p>
                      </Note>
                    </>
                  ),
                },
                {
                  title: t('pii_import_3_title'),
                  content: (
                    <p>
                      <Trans i18nKey="pii_import_3_text" />
                    </p>
                  ),
                },
                {
                  title: t('pii_import_4_title'),
                  content: (
                    <p>
                      <Trans i18nKey="pii_import_4_text" />
                    </p>
                  ),
                },
                {
                  title: t('pii_import_5_title'),
                  content: (
                    <p>
                      <Trans i18nKey="pii_import_5_text" />
                    </p>
                  ),
                },
              ]}
            />
          </InfoSection>
          <SectionTitle subtitle={t('pii_channels_subtitle')} centered>
            <Trans i18nKey={t('pii_channels_title')} />
          </SectionTitle>
          <ChannelSection id="channels">
            <Table
              headers={channelsTableHeaders}
              rows={importChannelsTabledata}
              caption={`${t('index_channels_table_caption_import')}:`}
            />
            <StaticImage
              src="../assets/images/features/product-information-import/pii-table.png"
              alt={t('index_channels_table_caption_import')}
              width={376}
              height={376}
              quality={100}
              placeholder="blurred"
            />
          </ChannelSection>
          <CTA url={LINKS.REGISTER} variant={LinkVariant.BRAVO}>
            <Trans i18nKey="common_try_free_of_charge" />
          </CTA>
        </Content>
        <ContactForm />
      </HeadingScope>
    </Layout>
  );
};

export default PIIPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <SEO
    title="Product Information Import | OmniGet"
    description="Import product data with ease using OmniGet. Quickly upload and integrate new product information to keep your catalog up-to-date."
  />
);
